<template>
    <div class="numeric-metric">
        <div :class="`${cclass} card-body text-center`">
            <p class="h1">
                {{ fromatter.format(value) }}
            </p>
            <span>{{ title }} ({{ unit }})</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            default: () => 0,
            requied: true
        },

        title: {
            type: String,
            default: () => '',
            required: true
        },

        unit: {
            type: String,
            default: () => '',
            required: true
        },

        cclass: {
            type: String,
            default: () => '',
            required: false
        }
    },

    data() {
        return {
            fromatter: new Intl.NumberFormat()
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../_custom.scss';

.numeric-metric {
    @extend .card, .flex-grow-1, .m-4;
    height: fit-content;
}
</style>