class Operation {
    constructor(operation, config=undefined) {
        if (operation == undefined)
            operation = 'sum';

        if (operation != 'sum' && operation != 'mean')
            throw `Operation '${operation}' not supported`;

        this.operation = operation;

        if (config == undefined)
            return;

        if (config.decimals != undefined)
            this._decimals = config.decimals;
    }

    compute(list, objectProp=undefined) {
        let result = 0;

        switch (this.operation) {
            case "sum":
                result = objectProp == undefined ?
                    this._sum(list) : this._sum(list, objectProp);
                break;

            case "mean":
                result = objectProp == undefined ?
                    this._mean(list) : this._mean(list, objectProp);
                break;
        }

        return this._decimals != undefined ?
            Number(result.toFixed(this._decimals)) : result;
    }

    _sum(list, prop=undefined) {
        if (prop == undefined)
            return list.reduce((a, b) => a + b);

        let sum = 0;
        list.forEach(f => { sum += f[prop] });

        return sum;
    }

    _mean(list, prop=undefined) {
        const sum = this._sum(list, prop);
        return sum / list.length;
    }
}

export default Operation;